body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menuholder {
  position: absolute;
  top: 0;
  right: -210px;
  z-index: 5;
  background-color: #fff;
  padding: 80px 36px 0;
  min-height: 100%;
  transition: right 0.3s ease-in-out;
}

* {
  font-family: Poppins;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h4 > strong {
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h5 {
  font-size: 17px;
  font-weight: 600;
}

a {
  color: #00537f;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul > li {
  margin: 0;
  padding: 0;
}
